<template>
  <div class="policyCompilation">
    <div class="policyCompilationTitle">
      <p>找项目：科技企业集聚追踪系统(精准招商)</p>
      <p style="font-size: 16px;">企业追踪【招商OA】</p>
    </div>
    <el-tabs v-model="activeName2" @tab-click="handleClick">
      <el-tab-pane label="招商OA系统" name="first">
        <industryProject />
      </el-tab-pane>
      <el-tab-pane label="招商系统分析" name="second">
        <systemAnalysis />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
// import oaSystem from './oaSystem.vue'
import industryProject from "./industryProject.vue";
import systemAnalysis from "./systemAnalysis.vue";
export default {
  data() {
    return {
      activeName2: localStorage.getItem("activeName2")
        ? localStorage.getItem("activeName2")
        : "first"
    };
  },
  components: {
    industryProject,
    systemAnalysis
  },
  methods: {
    handleClick(tab) {
      localStorage.setItem("activeName2", tab.name);
    }
  }
};
</script>

<style scoped>
.policyCompilationTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  height: 60px;
}
</style>
