<template>
  <div class="industryProject">
    <el-form ref="form" :model="formData" label-width="80px" size="mini">
      <el-form-item label="层次">
        <el-checkbox-group v-model="formData.arrangement_id" @change="changeHandel">
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in cenciData"
            :key="index"
          >{{ item.title }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="领域">
        <el-radio-group v-model="formData.industry_id" @change="changeHandel">
          <el-radio
            :label="item.id"
            :name="item.title"
            v-for="(item, index) in industryData"
            :key="index"
          >{{ item.title }}</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="标签">
        <el-checkbox-group v-model="formData.label_id" @change="changeHandel">
          <el-checkbox
            :label="item.id"
            v-for="(item, index) in labelsData"
            :key="index"
          >{{ item.title }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item label="时间">
        <el-date-picker
          v-model="formData.date"
          type="date"
          placeholder="选择日期"
          @change="changeHandel"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="阶段">
        <el-checkbox-group
          v-model="formData.oa_stage_id"
          style="display: inline-block;"
          @change="changeHandel"
        >
          <el-checkbox
            style="margin-bottom: 6px"
            :label="item.id"
            v-for="item in tlStage"
            :key="item.id"
            v-show="item.is_check"
          >{{ item.title }}</el-checkbox>
          <el-button size="mini" style="margin-left: 15px;" @click="setStages">阶段设置</el-button>
        </el-checkbox-group>
        <div class="policy-btn">
          <el-button size="mini" type="primary" @click="$router.push('/policyExpress')">政策速递</el-button>
        </div>
      </el-form-item>
    </el-form>

    <el-table border :data="tableData" style="width: 100%;">
      <el-table-column prop="pro_name" label="企业/项目名称" align="center" width="160"></el-table-column>

      <el-table-column label="品牌" align="center" width="160">
        <template v-slot="scope">
          <span v-for="(item, index) in scope.row.brand" :key="index">{{ item.title }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="arrangement.title" label="层次" align="center" width="160"></el-table-column>

      <el-table-column prop="morph_industry" label="领域" align="center" width="160">
        <template v-slot="scope">
          <el-tag
            style="margin: 0 2px"
            size="mini"
            v-for="item in scope.row.morph_industry"
            :key="item.id"
          >{{ item.title }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="label.title" label="标签" align="center" width="160"></el-table-column>

      <el-table-column label="地区" align="center" width="160">
        <template v-slot="scope">
          <el-tag
            style="margin: 0 2px"
            size="mini"
            v-for="item in scope.row.morph_area"
            :key="item.id"
          >{{ item.title }}</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="融资阶段" align="center" width="160">
        <template v-slot="scope">
          <span
            style="margin: 0 2px"
            size="mini"
            v-for="item in scope.row.morph_stage"
            :key="item.id"
          >{{ item.title }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="pro_total_score" label="得分" align="center" width="120"></el-table-column>

      <el-table-column label="潜在投资方" align="center" width="160">
        <template v-slot="scope">
          <span
            style="color: #409EFF;text-decoration: underline;cursor: pointer;"
            @click="touzi(scope.row)"
          >{{ scope.row.match_list_counts }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="contact_user_mobile" label="联系方式" align="center" width="160"></el-table-column>

      <el-table-column prop="contact_user_name" label="联系人" align="center" width="160"></el-table-column>

      <!-- <el-table-column label="跟进单位/部门" align="center" width="200">
        <template v-slot="scope">
          <span class="genjin" v-for="(item, index) in scope.row.roles" :key="index">{{ item }}</span>
        </template>
      </el-table-column>-->

      <el-table-column label="跟进人及其单位" align="center" width="160">
        <template slot-scope="scope">
          <div style="display:flex;justify-content:space-around">
            <div>
              <span>{{ scope.row.user}}</span>
            </div>
            <div>
              <div v-for="(item, index) in scope.row.roles" :key="index">{{ item }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="跟进阶段" align="center" width="160">
        <template v-slot="scope">
          <span
            class="genjin"
            v-for="(item, index) in scope.row.stage"
            :key="index"
          >{{ item.title }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="操作" align="center" fixed="right" width="360">
        <template v-slot="scope">
          <el-button @click="projectEdit(scope.row)" type="primary" size="mini">编辑</el-button>
          <el-button @click="viewLog(scope.row)" type="primary" size="mini">查看日志</el-button>
          <el-button @click="genjinStages(scope.row)" type="primary" size="mini">跟进阶段</el-button>
          <el-button @click="getTask(scope.row)" type="primary" size="mini">任务分发</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pages">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-count="pageCount"
        layout="total,prev,pager,next,jumper"
        :total="total"
      ></el-pagination>
    </div>

    <el-dialog :visible.sync="translateActive" width="90%">
      <el-table :data="translateData" border style="width: 100%;">
        <el-table-column
          prop="investor_name"
          label="姓名"
          width="160"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="investor_company"
          label="公司名称"
          width="240"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="investor_brand"
          label="品牌名称"
          width="160"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="investor_mobile"
          label="联系方式"
          align="center"
          width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="investor_email"
          label="邮箱"
          width="220"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column property="instruction" label="投资领域" align="center" width="400">
          <template v-slot="scope">
            <span
              class="genjin"
              style="margin:2px 2px"
              v-for="(item, index) in scope.row.morph_industry"
              :key="index"
            >{{ item.title }}</span>
          </template>
        </el-table-column>
        <el-table-column property="lunci" label="投资轮次" align="center">
          <template v-slot="scope">
            <span
              style="margin:2px 2px"
              v-for="(item, index) in scope.row.morph_stage"
              :key="index"
            >{{ item.title }}</span>
          </template>
        </el-table-column>
        <el-table-column
          property="data_total_money"
          label="投资金额"
          width="160"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="investor_desc"
          label="具体描述"
          width="160"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="match_weight"
          label="匹配度"
          width="160"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <div class="pages">
        <el-pagination
          background
          @current-change="handleCurrentChange2"
          :current-page="1"
          :page-count="pageCount2"
          layout="total,prev,pager,next,jumper"
          :total="total2"
        ></el-pagination>
      </div>
    </el-dialog>

    <!-- 编辑  start -->
    <el-dialog :visible.sync="editActive" width="800px">
      <el-form label-width="110px" size="mini" :data="editDate" v-if="editActive">
        <div class="form">
          <div class="form_item">
            <el-form-item label="企业/项目名称">
              <el-input class="itemInp" v-model="editDate.pro_name" disabled></el-input>
            </el-form-item>

            <el-form-item label="层次">
              <el-select placeholder="请选择" class="itemInp" v-model="cengci">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, index) in cenciData"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="标签">
              <el-select placeholder="请选择" class="itemInp" v-model="biaoqian">
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, index) in labelsData"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="融资阶段">
              <span
                style="height:28px;line-height:1;border:1px solid #E4E7ED; background-color: #F5F7FA;border-radius: 5px;color: #C0C4CC;cursor:not-allowed;padding:5px 12px;width: 200px;display: inline-block;"
              >{{ editDate.morph_stage.length > 0 ? editDate.morph_stage[0].title : '' }}</span>
            </el-form-item>
          </div>
          <div class="form_item">
            <el-form-item label="品牌名称">
              <span
                style="height:28px;line-height:1;border:1px solid #E4E7ED; background-color: #F5F7FA;border-radius: 5px;color: #C0C4CC;cursor:not-allowed;padding:5px 12px;width: 200px;display: inline-block;"
              >{{ editDate.brand.length > 0 ? editDate.brand[0].title : '' }}</span>
            </el-form-item>

            <el-form-item label="领域">
              <el-checkbox-group v-model="linyu">
                <el-checkbox
                  :label="item.id"
                  :name="item.title"
                  v-for="(item, index) in industryData"
                  :key="index"
                >{{ item.title }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <el-form-item label="地区">
              <span
                style="height:28px;line-height:28px;border:1px solid #E4E7ED; background-color: #F5F7FA;border-radius: 5px;color: #C0C4CC;cursor:not-allowed;padding:0px 12px;width: 200px;display: inline-block;"
              >
                <span
                  class="genjin"
                  v-for="(item, index) in editDate.morph_area"
                  :key="index"
                >{{ item.title }}</span>
              </span>
            </el-form-item>
            <div class="form">
              <el-form-item label="联系人">
                <el-input disabled v-model="editDate.contact_user_name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input disabled v-model="editDate.contact_user_mobile"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>

        <div class="formFooter">
          <el-button @click="editActive = false" size="mini">取消</el-button>
          <el-button type="primary" @click="editProject" size="mini">确定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 编辑  end -->

    <!--阶段设置 start  -->
    <div class="model">
      <el-dialog title="阶段设置" :visible.sync="stageSetActive" width="500px">
        <el-button type="text" size="mini" @click="addFirstStage" style="margin-bottom: 8px">添加一级阶段</el-button>
        <el-tree
          :data="tlStage"
          show-checkbox
          :default-checked-keys="isSelected"
          node-key="id"
          ref="tree"
          :default-expanded-keys="expanded"
          :expand-on-click-node="false"
          @node-drag-end="handleDragEnd"
          @check="checkChange"
          draggable
          :allow-drop="allowDrop"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div style="display: flex;align-items: center;">
              <span class="nodeClass">{{ data.sort }}</span>
              <span
                style="width: 250px;display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
              >{{ data.title }}</span>
            </div>
            <span>
              <el-button
                v-if="data.pid === 0"
                type="text"
                size="mini"
                @click="() => append(data)"
                style="padding: 0 !important;"
              >添加</el-button>
              <el-button
                type="text"
                size="mini"
                @click="() => edit(node, data)"
                style="padding: 0 !important;"
              >编辑</el-button>
              <el-button
                type="text"
                size="mini"
                @click="() => remove(node, data)"
                style="padding: 0 !important;"
              >删除</el-button>
            </span>
          </span>
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="stageSetActive = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="submitData" size="mini">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!--阶段设置 end -->

    <!-- 跟进阶段 start-->
    <div class="model">
      <el-dialog :visible.sync="followUpActive" width="30%" title="跟进阶段">
        <el-tree
          :data="followData"
          :default-expanded-keys="followUpKey"
          show-checkbox
          node-key="id"
          :default-checked-keys="followUpKey"
          ref="tree2"
          check-strictly
          highlight-current
          @check-change="handleClick"
          :props="defaultProps"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <div style="display: flex;align-items: center;">
              <span class="nodeClass">{{ data.sort }}</span>
              <span>{{ data.title }}</span>
            </div>
          </span>
        </el-tree>
        <span slot="footer" class="dialog-footer">
          <el-button @click="followUpActive = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="followUpSubmit" size="mini">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 跟进阶段  end-->

    <!-- 任务分发 start -->
    <div class="model">
      <el-dialog title="任务分发" :visible.sync="taskAcitve" width="680px" :before-close="handleClose">
        <div style="display: flex;justify-content: flex-end;margin-bottom: 15px;">
          <el-button size="mini" type="primary" @click="addTask">添加</el-button>
        </div>
        <el-form :inline="true" size="mini">
          <div class="fromControl" v-for="(item, index) in taskFrom" :key="index">
            <div>
              <el-form-item label="项目阶段" style="margin-right:10px!important">
                <el-cascader
                  v-model="item.oa_stage_id"
                  :options="Taskstages"
                  :props="{ value: 'id', label: 'title', children: 'children' }"
                  placeholder="请选择项目阶段"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="跟进人">
                <el-select v-model="item.organ_manager_id" placeholder="请选择跟进人">
                  <el-option
                    :label="item2.name"
                    :value="item2.organ_manager_id"
                    v-for="(item2, index2) in genjinren"
                    :key="index2"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-form-item>
                <el-button type="primary" size="mini" @click="delTask(item, index)">删除</el-button>
                <el-button type="primary" size="mini" @click="taskClick(item)">确定</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-dialog>
    </div>
    <!-- 任务分发 end -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
      pageCount: Math.ceil(this.total / 10),
      total: 0,
      page: 1,
      limit: 10,
      currentPage2: 1,
      pageCount2: Math.ceil(this.total2 / 10),
      total2: 0,
      page2: 1,
      limit2: 10,
      orgin_id: "",
      formData: {
        arrangement_id: [],
        industry_id: 0,
        label_id: [],
        date: "",
        oa_stage_id: []
      },
      tableData: [],
      checkList: [],
      cenciData: [], //层次
      industryData: [
        { id: 1, title: "创投行业分类" },
        { id: 2, title: "国民经济行业分类" },
        { id: 3, title: "军民融合专项分类" }
      ], //行业领域
      labelsData: [], //标签
      /* 阶段设置 start */
      tlStage: [],
      isSelected: [],
      expanded: [],
      listShowss: [],
      halfCheckedKeys: [],
      stageSelect: [],
      listShow: [],
      stageSetActive: false,
      /* 阶段设置 end */
      /* 投资详情 start */
      translateActive: false,
      translateData: [],
      touziID: "",
      /*投资详情 end  */

      //编辑
      editActive: false,
      editId: "",
      editDate: [],
      biaoqian: "",
      cengci: "",
      linyu: [],
      stage_id: "",
      //跟进阶段
      genjinID: "",
      followUpActive: false,
      followData: [],
      defaultProps: {
        children: "children",
        label: "label"
      },
      followUpKey: [],
      //任务分发
      taskAcitve: false,
      genjinren: [],
      Taskstages: [],
      TaskID: "",
      taskFrom: [
        {
          oa_stage_id: [],
          organ_manager_id: []
        }
      ]
    };
  },
  mounted() {
    this.orgin_id = sessionStorage.organizationId;
    this.pub();
    this.getProjects();
    this.getarrangements();
    this.getLabels();
  },

  methods: {
    handleCurrentChange2(val) {
      this.page2 = val;
      this.$http
        .get(this.URL.adminUrl.qianzai + this.touziID, {
          params: {
            limit: this.limit2,
            page: this.page2
          }
        })
        .then(res => {
          this.total2 = res.data.data.meta.total;
          this.translateData = res.data.data.data;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getProjects();
    },
    changeHandel() {
      this.page = 1;
      this.getProjects();
    },
    getarrangements() {
      this.$http.get(this.URL.adminUrl.arrangements).then(res => {
        this.cenciData = res.data.data;
      });
    },

    getLabels() {
      this.$http.get(this.URL.adminUrl.labels).then(res => {
        this.labelsData = res.data.data;
      });
    },
    /* 获取项目列表 */
    getProjects() {
      this.$http
        .get(this.URL.adminUrl.projects, {
          params: {
            limit: this.limit,
            page: this.page,
            organ_id: this.orgin_id,
            arrangement_id: this.formData.arrangement_id,
            industry_id: this.formData.industry_id,
            label_id: this.formData.label_id,
            oa_stage_id: this.formData.oa_stage_id,
            created_at: this.formData.date
          }
        })
        .then(res => {
          this.total = res.data.data.meta.total;
          this.tableData = res.data.data.data;
          this.tableData.forEach(item => {
            if (item.project_managers.length > 0) {
              item.user = item.project_managers[0].user;
              item.roles = item.project_managers[0].roles;
            }
          });
        })
        .catch(err => {
          this.$message.error(err.response.data.message);
        });
    },
    /* 查看日志*/
    viewLog(row) {
      this.$router.push(
        "/viewLog?project_id=" + row.id + "&name=" + row.pro_name
      );
    },
    /*  */
    handleNodeClick(data) {
      console.log(data);
    },
    /*  */
    touzi(row) {
      this.translateActive = true;
      this.translateData = [];
      this.touziID = row.project_id;
      this.page2 = 1;
      this.$http
        .get(this.URL.adminUrl.qianzai + row.project_id, {
          params: {
            limit: this.limit2,
            page2: this.page2
          }
        })
        .then(res => {
          this.total2 = res.data.data.meta.total;
          this.translateData = res.data.data.data;
        });
    },
    // 添加一级阶段
    addFirstStage() {
      this.$prompt("请输入一级阶段名称", "添加一级阶段", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{1,20}$/,
        inputErrorMessage: "长度不能超过20个字符"
      })
        .then(({ value }) => {
          if (value === null) {
            this.$message.error("请输入节点名称");
            return false;
          } else {
            let obj = {
              title: value,
              sort: this.tlStage.length + 1,
              is_check: 1
            };
            this.$http
              .post(this.URL.adminUrl.stages, obj)
              .then(res => {
                this.pub();
              })
              .catch(err => {
                this.$message.error(err.response.data.message);
              });
          }
        })
        .catch(() => {});
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.tlStage.forEach((item, index) => {
        item.sort = index + 1;
        item.children &&
          item.children.forEach((a, b) => {
            a.sort = b + 1;
          });
        item.pid = 0;
        if (!item.children) {
          item.children = [];
        }
      });
      this.$refs.tree.setCheckedKeys(this.isSelected);
    },
    checkChange(a, b) {
      a.is_check = a.is_check == 1 ? 0 : 1;

      this.$http.put(this.URL.adminUrl.delStags + "/" + a.id, {
        is_check: a.is_check
      });
      this.$refs["tree"].children.forEach(item => {
        if (item.id == a.pid) {
          for (var i = 0; i < item.children.length; i++) {
            if (item.children[i].is_check == 0) {
              item.is_check = 0;
              break;
            } else {
              item.is_check = 1;
            }
          }
          this.$http.put(this.URL.adminUrl.delStags + "/" + item.id, {
            is_check: item.is_check
          });
        }
      });
    },
    allowDrop(moveNode, inNode, type) {
      if (moveNode.level === 1 && inNode.level === 1) {
        // 四种情况
        if (moveNode.nextSibling === undefined) {
          return type === "prev";
        } else if (inNode.nextSibling === undefined) {
          return type === "next";
        } else if (moveNode.nextSibling.id !== inNode.id) {
          return type === "prev";
        } else {
          return type === "next";
        }
      }
      // 是否为同级下的子节点
      if (
        moveNode.level === 2 &&
        inNode.level === 2 &&
        moveNode.parent.id === inNode.parent.id
      ) {
        // 四种情况
        if (moveNode.nextSibling === undefined) {
          return type === "prev";
        } else if (inNode.nextSibling === undefined) {
          return type === "next";
        } else if (moveNode.nextSibling.id !== inNode.id) {
          return type === "prev";
        } else {
          return type === "next";
        }
      }
      // 不同级下的子节点
      if (
        moveNode.level === 2 &&
        inNode.level === 1 &&
        moveNode.parent.id !== inNode.parent.id
      ) {
        // 四种情况
        if (moveNode.nextSibling === undefined) {
          return type === "prev";
        } else if (inNode.nextSibling === undefined) {
          return type === "next";
        } else if (moveNode.nextSibling.id !== inNode.id) {
          return type === "prev";
        } else {
          return type === "next";
        }
      }
    },
    // 添加阶段
    append(data) {
      this.expanded.splice(0, this.expanded.length);
      this.$prompt("请输入阶段名称", "添加节点名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{1,20}$/,
        inputErrorMessage: "长度不能超过20个字符"
      })
        .then(({ value }) => {
          if (value === null || value.trim() === "") {
            this.$message.error("请输入阶段名称");
            return false;
          }
          let newChild = {};
          if (!data.children) {
            this.$set(data, "children", []);
            newChild = {
              title: value,
              sort: 1,
              is_check: 0,
              pid: data.id
            };
          } else {
            // 点击的当前这个阶段由子集
            newChild = {
              title: value,
              sort: data.children.length + 1,
              is_check: 1,
              pid: data.id
            };
          }
          this.$http.post(this.URL.adminUrl.stages, newChild).then(res => {
            this.expanded.push(data.id);
            this.pub();
          });
        })
        .catch(() => {});
    },
    // 编辑节点名称
    edit(a, b, scope) {
      // console.log(a, b, '东方季道看风景的看风景的空间', scope)
      this.$prompt("请输入阶段名称", "编辑阶段名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{1,20}$/,
        inputErrorMessage: "长度不能超过20个字符",
        inputValue: b.title
      })
        .then(({ value }) => {
          if (value === null) {
            this.$message.error("请输入节点名称");
            return false;
          } else {
            this.$http
              .put(this.URL.adminUrl.delStags + "/" + b.id, {
                title: value,
                is_check: 1
              })
              .then(res => {
                b.title = value;
              });
          }
        })
        .catch(() => {});
    },
    // 删除阶段
    remove(node, data) {
      this.$confirm("确认删除", "提示", {
        showClose: false
      })
        .then(() => {
          this.$http
            .delete(this.URL.adminUrl.delStags + "/" + data.id)
            .then(res => {
              const parent = node.parent;
              const children = parent.data.children || parent.data;
              const index = children.findIndex(d => d.id === data.id);
              children.splice(index, 1);
              this.tlStage.forEach((item, index) => {
                item.sort = index + 1;
                item.childrens &&
                  item.childrens.forEach((a, b) => {
                    a.sort = b + 1;
                  });
              });
              this.pub();
              // 重新确定有哪些
            })
            .catch(err => {
              this.$message.error(err.response.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    submitData() {
      this.tlStage.forEach((item, index) => {
        if (item.children == undefined) {
          item.children = [];
        }
      });
      this.$http
        .post(this.URL.adminUrl.treeSort, this.tlStage)
        .then(res => {
          this.stageSetActive = false;
          this.pub();
        })
        .catch(err => {
          this.$message.error(err.response.data.message);
        });
    },
    setStages() {
      this.stageSetActive = true;
      this.pub();
    },
    pub() {
      this.isSelected = [];
      this.$http.get(this.URL.adminUrl.stages).then(res => {
        this.tlStage = res.data.data;
        this.followData = res.data.data;
        this.Taskstages = this.getTreeData(res.data.data);
        this.tlStage.forEach((item, index) => {
          item.sort = index + 1;
          if (item.children && item.children.length > 0) {
            item.children.forEach((itemchild, indexchild) => {
              itemchild.sort = indexchild + 1;
              if (itemchild.is_check === 1) {
                this.isSelected.push(itemchild.id);
              }
            });
          }
          if (item.is_check === 1) {
            this.isSelected.push(item.id);
          }
        });
      });
    },
    getTreeData(data) {
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        }
      }
      return data;
    },
    //任务分发获取数据
    getTask(row) {
      this.taskAcitve = true;
      this.TaskID = row.id;
      this.$http
        .get(this.URL.adminUrl.projectUsers + this.orgin_id)
        .then(res => {
          this.genjinren = res.data.data;
        });

      this.$http
        .get(this.URL.adminUrl.taskRelease, {
          params: {
            project_id: this.TaskID,
            organ_id: this.orgin_id
          }
        })
        .then(res => {
          if (res.data.data.data.data.length > 0) {
            this.taskFrom = res.data.data.data.data;
          }
        });
    },
    //添加任务分发列表
    addTask() {
      this.taskFrom.push({
        oa_stage_id: "",
        organ_manager_id: ""
      });
    },
    handleClose(done) {
      this.$confirm("确认任务分发已全部提交并关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    //删除任务分发列表
    delTask(item, index) {
      this.$confirm("确定删除？", "提示")
        .then(() => {
          if (this.taskFrom.length > 1) {
            if (item.id) {
              this.$http
                .delete(this.URL.adminUrl.taskRelease + "?id=" + item.id)
                .then(res => {
                  this.taskFrom.splice(index, 1);
                  this.$message({
                    type: "success",
                    message: "删除成功!"
                  });
                });
            } else {
              this.taskFrom.splice(index, 1);
              this.$message({
                type: "success",
                message: "删除成功!"
              });
            }
          } else {
            this.$message.error("最后一条不能删除！");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //提交任务分发
    taskClick(data) {
      if (data.oa_stage_id == "") {
        this.$message.error("项目阶段不能为空！");
        return false;
      }
      if (data.organ_manager_id == "") {
        this.$message.error("跟进人不能为空!");
        return false;
      }

      if (data.id) {
        this.$http
          .put(this.URL.adminUrl.editTask + data.id, {
            organ_manager_id: data.organ_manager_id,
            oa_stage_id: data.oa_stage_id[data.oa_stage_id.length - 1]
              ? data.oa_stage_id[data.oa_stage_id.length - 1]
              : data.oa_stage_id
          })
          .then(res => {
            this.$message({
              message: "更新成功",
              type: "success"
            });
          })
          .catch(err => {
            this.$message.error(err.response.data.message);
          });
      } else {
        this.$http
          .post(this.URL.adminUrl.taskRelease, {
            project_id: this.TaskID,
            organ_manager_id: data.organ_manager_id,
            oa_stage_id: data.oa_stage_id[data.oa_stage_id.length - 1],
            organ_id: this.orgin_id
          })
          .then(res => {
            this.$message({
              message: "添加成功！",
              type: "success"
            });
          })
          .catch(err => {
            this.$message.error(err.response.data.message);
          });
      }
    },
    //编辑OA
    projectEdit(row) {
      this.editActive = true;
      this.editId = row.project_id;
      this.editDate = row;
      this.stage_id = row.morph_stage.length > 0 ? row.morph_stage[0].id : 0;
      this.cengci = "";
      this.biaoqian = "";
      this.linyu = [];
      this.$http
        .get(this.URL.adminUrl.projectEdit, {
          params: {
            project_id: this.editId,
            organ_id: this.orgin_id
          }
        })
        .then(res => {
          this.cengci = res.data.data.arrangement_id;
          this.biaoqian = res.data.data.label_id;
          this.linyu = res.data.data.industry_id;
        });
    },
    //提交编辑
    editProject() {
      var area = "";
      this.editDate.morph_area.length > 0 &&
        this.editDate.morph_area.forEach(item => {
          area += item.title;
        });
      this.$http
        .post(this.URL.adminUrl.editProject, {
          project_id: this.editId, //项目id	int	项目列表的单条数据id	是
          organ_id: this.orgin_id, //机构id	int		是
          title: this.editDate.pro_name, //项目/企业名	string	通过列表取来的	是
          brand_name:
            this.editDate.brand.length > 0 ? this.editDate.brand[0].title : "", //品牌名	string	通过列表取来的	是
          stage_id: this.stage_id, //融资轮次id	int	通过列表取来的	是
          contacts:
            this.editDate.contact_user_name == null
              ? ""
              : this.editDate.contact_user_name, //联系人	string	通过列表取来的	是
          tel:
            this.editDate.contact_user_mobile == null
              ? ""
              : this.editDate.contact_user_mobile, //联系电话	string	通过列表取来的	是
          address: area, //地址	string	通过列表取来的	是
          industry: this.linyu, //领域数组	array		是
          arrangement_id: this.cengci ? this.cengci : 0, //层次int		是
          label_id: this.biaoqian ? this.biaoqian : '' //标签id	 int		是
        })
        .then(res => {
          this.editActive = false;
          this.getProjects();
        });
    },
    genjinStages(row) {
      this.genjinID = row.project_id;
      this.$http
        .get(this.URL.adminUrl.projectEdit, {
          params: {
            project_id: row.project_id,
            organ_id: this.orgin_id
          }
        })
        .then(res => {
          this.followUpActive = true;
          if (res.data.data.oa_stage_id) {
            this.followUpKey.push(res.data.data.oa_stage_id);
          }
        });
    },
    handleClick(data, checked, node) {
      if (checked) {
        this.$refs.tree2.setCheckedNodes([data]);
      }
    },
    followUpSubmit() {
      this.$http
        .post(this.URL.adminUrl.editProject, {
          project_id: this.genjinID, //项目id	int	项目列表的单条数据id	是
          organ_id: this.orgin_id, //机构id	int		是
          oa_stage_id: this.$refs.tree2.getCheckedKeys()[0]
            ? this.$refs.tree2.getCheckedKeys()[0]
            : ""
        })
        .then(res => {
          this.followUpActive = false;
          this.getProjects();
        });
    }
  }
};
</script>

<style scoped lang="less">
.form {
  display: flex;
}

.itemInp {
  width: 220px;
}

.formFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.formFooter button {
  width: 140px;
}

.custom-tree-node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #606266;
}

.nodeClass {
  margin-right: 15px;
  width: 16px;
  height: 16px;
  border: 1px solid #606266;
  border-radius: 50%;
  color: #606266;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fromControl {
  display: flex;
  justify-content: space-between;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
</style>
<style>
.model .el-dialog__body {
  padding-top: 0px !important;
}

.model .el-form--inline .el-form-item {
  margin-right: 0 !important;
}
.genjin:after {
  content: "/";
  color: #cccccc;
}
.genjin:last-of-type:after {
  content: "" !important;
}
</style>
//  三期新增
<style lang="less">
.industryProject {
  .policy-btn {
    text-align: right;
  }
}
</style>
